import React, {useEffect, useState} from 'react';
import { DefineRoutes } from '../../../AppSystem/Routing/DefineRoutes';
import { ChangeLink } from './ChangeLink';
import {Link} from "react-router-dom";
import {CheckMobile} from "../../../AppSystem/Helpers/CheckMobile";
import {SaveLocalStorage} from "../../../Common/Helpers/SaveLocalStorage";
import {fireEvent} from "@testing-library/react";
import {LoadLocalStorage} from "../../../Common/Helpers/LoadLocalStorage";
import {handleInputChangeSearch} from "../../../Common/Components/Lists/SortableList";
import {CustomReload} from "../../../AppSystem/Helpers/CustomReload";

/////////////////// IMPORTANT: WAS CHANGED "index" FOR "route.name.trim()"

const Sidebar = ({ toggle, activeMarket, /*onMenuClick*/ }) => {
  const routes = DefineRoutes();
  const [activePath, setActivePath] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const windowWidth = CheckMobile(1025);
  const [isHovered, setIsHovered] = useState(false);

  // const [activeItem, setActiveItem] = useState(null); //para ver que li esta activo
  const handleItemClick = (index) => {
    if(activePath === `dropdown${index}`){
      setIsExpanded(!isExpanded);
    } else {
      setIsExpanded(true);
    }
    // setActiveItem(index);
  };

  /*
  const handleMouseEnter = classValue => {
    setIsHovered(true);
    const element = document.querySelector(`.${classValue}`);
    if (toggle === true) {
      element.current.classList.add('d-block');
    }
  };

  const handleMouseLeave = classValue => {
    setIsHovered(false);
    const element = document.querySelector(`.${classValue}`);
    if (toggle === true) {
      element.current.classList.remove('d-block');
    }

  };
   */

  const changeName = newTitle => {
    // onMenuClick(newTitle);
   // SaveLocalStorage('headerTitle', newTitle);
    localStorage.removeItem('currentPage');
    localStorage.removeItem('itemsPerPage');
  };

  const handleLinkClick = link => {
    setActivePath(link);
  };

  // EXECUTE ONCHANGE SEARCH INPUT (SIMULATE CLEAR)
  // IMPORTANT: THIS IS A TEMPORARY SOLUTION, IT IS NOT THE BEST WAY TO DO IT. ONLY WORKING IN LIST PAGES
  const clearSearchCriteria = (label) => {
    const search = LoadLocalStorage("search");
    if (search) {
      localStorage.removeItem('search');
      const searchCriteria = document.getElementById('searchCriteria');
      if (searchCriteria) {
        const title = LoadLocalStorage("headerTitle");
        if (title && title === label) {
          CustomReload();
        }
      }
    }
  };

  //functions for shortcutCreate true
  // const [hovered, setHovered] = useState('');
  // const handleMouseEnter2 = path => {
  //   setHovered(path);
  // };
  // const handleMouseLeave2 = () => {
  //   setHovered('');
  // };

  return (
    <>
      <ul className="metismenu list-unstyled" id="side-menu">
        {routes.map((route, index) =>
          route.hideInMenu === undefined || (route.hideInMenu !== undefined && route.hideInMenu !== true) ? (
            // Dropdown
            route.dropdown !== undefined && route.dropdown === true ? (
              <React.Fragment key={'menuTitle'+route.name.trim()}>
                {/* Title if has*/}
                {route.title !== undefined && route.title !== null ? (
                  <li className="menu-title" data-key="t-menu" key={`dropdown${route.name.trim()}`}>
                    {toggle === true ? '' : route.title}
                  </li>
                ) : (
                  ''
                )}

                {route.subs.length > 0 ? (
                    <li className={`dropdown ${activePath === `dropdown${route.name.trim()}` && activeMarket !== false ? 'active' : ''}`}
                        id={`liId${route.name.trim()}`}
                        key={`dropdownActive${route.name.trim()}`}
                        onMouseEnter={() => {
                          if(toggle === true && !isHovered){
                            setIsHovered(true);
                            handleLinkClick(`dropdown${route.name.trim()}`);
                          }
                        }}
                        onMouseLeave={() => {
                          if(toggle === true ){
                            setIsHovered(false);
                          }
                        }}
                        onClick={() => {
                          if(toggle === true ){
                            setIsHovered(!isHovered);
                          }
                        }}
                    >
                      <Link
                          to={`#dropdown${route.name.trim()}`}
                          className={`${isExpanded && activePath === `dropdown${route.name.trim()}` ? 'has-arrow-close' : 'has-arrow'} ${toggle === true && !windowWidth ? ' has-arrow-color' : ''}`} // d-flex justify-content-center
                          role="button"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          onClick={() => {
                            handleItemClick(route.name.trim());
                            handleLinkClick(`dropdown${route.name.trim()}`);
                          }}
                      >
                        <i className={`${route.icon} fs-5`} />

                        <span className="menu-item ">{toggle === true && !windowWidth ? '' : route.name}</span>
                      </Link>
                      <ul
                          className={`${toggle === true && !windowWidth ? 'sub-menu-especial' : 'sub-menu'} ${isExpanded === true && activePath === `dropdown${route.name.trim()}` ? (toggle === false ? '' :'collapse' ) : 'collapse'}   ${isHovered && activePath === `dropdown${route.name.trim()}` ? 'd-block' : ''} dropdown${route.name.trim()}`}
                          id={`dropdown${route.name.trim()}`}
                      >
                        {toggle === true && !windowWidth? <li className="title-menu d-block">{route.name}</li> : ''}
                        {route.subs
                            ? route.subs.map((item, index2) => {
                              return (
                                  <li className="border-warning" key={`${item.label}dropdownOptions${index2}`}>
                                    <Link
                                        to={item.path}
                                        onClick={() => {
                                          clearSearchCriteria(item.label);
                                          changeName(item.label);
                                        }}
                                    >
                                      {item.icon ? <i className={item.icon} /> : ''}
                                      <span className="px-2 border-warning">{item.label}</span>
                                    </Link>
                                  </li>
                              );
                            })
                            : ''}
                      </ul>
                    </li>

                ) : ''}

              </React.Fragment>
            ) : (
              // Normal link
              <>
                {/* Title if has*/}
                {route.title !== undefined && route.title !== null ? (
                  <li className="menu-title" data-key="t-menu" key={`normal${route.name.trim()}`}>
                    {toggle === true && !windowWidth ? '' : route.title}
                  </li>
                ) : (
                  ''
                )}
                <li key={`normalActive${route.name.trim()}`} className={activePath === route.path  && activeMarket !== false ? 'active' : ''}>
                  <ChangeLink
                    // mouseEnter={() => {
                    //   handleMouseEnter2(route.path);
                    // }}
                    // mouseLeave={handleMouseLeave2}
                      toggle={toggle}
                    icon={route.icon}
                    link={route.path}
                    onClick={() => {
                      clearSearchCriteria(route.name);
                      changeName(route.name);
                      handleLinkClick(route.path);
                    }}
                    label={toggle === true && !windowWidth ? '' : route.name}
                    //condition={hovered}
                    shortcutCreate={false}
                  />
                </li>
              </>
            )
          ) : (
            ''
          ),
        )}
      </ul>
    </>
  );
};

export default Sidebar;