import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Card, Col} from "reactstrap";
import SortableList from "../../Common/Components/Lists/SortableList";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useNavigate} from "react-router-dom";
import AdminResponseOpenPaymentModel from "../Models/Response/AdminResponseOpenPaymentModel";
import {ToCustomDecimal} from "../../AppSystem/Helpers/ToCustomDecimal";

export function BankTransferHistoryList ( ) {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const renderActionColumn = (item) => {
        return (
            <>           
            <CustomButton icon="bx bx-show pb-custom" iconColor="#157553" hoverIconColor='white' hoverButtonColor='#A3C2B8' iconSize={4}  buttonColor="#cde0da"  tooltipText={t('button.info')} customClasses="rounded py-2 mx-2" buttonBorder={false} id={item.id} onClick={() => {
                     navigate(`${ADMIN_PATHS.BANK_TRANSFERS_INFO}?id=${item.id}`);
                }} />
            </>
        );
    }
    
    const columns = [ "visualId", 'model','creditorName','creditorAccountIban','updatedAt', 'status', 'instructedAmount'];
    const listOptions = {
        "model": AdminResponseOpenPaymentModel,
        'columns':[
            "visualId", 'model','creditorName','creditorAccountIban','updatedAt', //añadir product, creditorAccountIban,instructedAmount
            {
                "field" : "status",
                "label" : t('modelLabel.status'),
                'insideClass' : (item)=> item.status === 'Betald' ?  'box-green' : 'box-red',
                "value": (item) => item.status === 'Betald' ? t('modelLabel.paid') :  item.status === 'Nekad' ? t('attest.denied') :t('modelLabel.unpaid') || "",
            },
            {
                "field" : "instructedAmount",
                "label" : t('modelLabel.amount'),
                'insideClass' : (item)=> item.instructedAmount >= 0 ? 'text-green text-end fixed-width' : 'text-red text-end fixed-width', //w-75
                "value": (item) => item.instructedAmount ? ToCustomDecimal(item.instructedAmount) +' kr' : "",
                'thClass': 'ps-5'
            }
        ],
        "actions": {
        "actionColumn" : renderActionColumn,
        },
        "exportIsAvailable": true,
        "urlGetListItemsToExport": "/open-payment/admin/search-paid",
        "nameFile": t('payReceive.bankAccount'),
        "ignoreFields" : columns,
        "urlGetSearch" : "/open-payment/admin/search-paid",

    };


    return(
        <>
            <Col lg={12} className="px-0">
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'mb-3 ms-3 backButton'}
                    onClick={() => {
                        navigate(ADMIN_PATHS.BANK_TRANSFERS_LIST)
                    }}
                />
            </Col>
            <Col lg={12} className=' pb-5 px-0'>
                <Card className='card-backend p-4'>
                    <h2 className="fw-bold rokkitt-font">{t('openPayment.historyBankList')}</h2>
                    <SortableList
                        listOptions={listOptions}
                    />
                </Card>

            </Col>

        </>
    );
}