import {ChangeDateFormat} from "../../../Common/Helpers/ChangeDateFormat";
import i18n from "i18next";
import {ToCustomDecimal} from "../../../AppSystem/Helpers/ToCustomDecimal";

class ResponseAccountRowModel {
    constructor({
                    id = '',
                    visualId = '',
                    accountId = '',
                    orgId = '',
                    model = '',
                    modelId = '',
                    modelInfo = [], //aqui hay que llamar al otro model con la info
                    description = '',
                    amount = 0,
                    swedbankFee = 0,
                    balance = 0,
                    createdAt = '',
                    createdBy = '',
                    createdByName = '',
                } = {}) {
        this.id = id;
        this.visualId = visualId;
        this.accountId = accountId;
        this.orgId = orgId;
        this.model = model;
        this.modelId = modelId;
        this.modelInfo = modelInfo;
        this.description = description;
        this.amount = amount;
        this.swedbankFee = swedbankFee;
        this.balance = balance;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.createdByName = createdByName;
    }

    static getAttributes(filter = null, validationSchema = null) {
        const createdBy = i18n.t('modelLabel.createdBy');

        const attributes =  {
            id :{
                'label': 'Id',
                'sortable': true,
                'sortField': 'id',
                'value': (item) => item.id || "",
            },
            visualId :{
                'label': i18n.t('modelLabel.id'),
                'sortable': true,
                'sortField': 'visualId',
                'value': (item) => item.visualId || "",
            },
            accountId :{
                'label': 'Account Id',
                'sortable': true,
                'sortField': 'accountId',
                'value': (item) => item.accountId || "",
            },
            orgId :{
                'label': 'Organization Id',
                'sortable': true,
                'sortField': 'orgId',
                'value': (item) => item.orgId || "",
            },
            model: {
                'label': i18n.t('modelLabel.type'),
                'sortable': true,
                'sortField': 'model',
                'value': (item) => item.model === 'Förrättning' ? i18n.t('reimbursement.reportProcessing') : item.model === 'Arvode' ? i18n.t('honorarium.honorarium') : item.model === 'Betala' ? i18n.t('payReceive.pay') : item.model === 'Intern transaktion' ? i18n.t('internalTransaction.internalTransaction') : item.model === 'Snabb betalning' ? i18n.t('payReceive.quickPayment') : item.model === 'Faktura' ? i18n.t('invoice.invoice') : item.model || "",
            },
            modelId: {
                'label': 'Model Id',
                'sortable': true,
                'sortField': 'modelId',
                'value': (item) => item.modelId || "",
            },
            modelInfo: {
                'label': 'Model Info',
                'sortable': true,
                'sortField': 'modelInfo',
                'value': (item) => item.modelInfo || "",
            },
            amount: {
                'label': i18n.t('modelLabel.amount'),
                'sortable': true,
                'sortField': 'amount',
                'insideClass' : (item)=> item.amount && item.amount >= 0 ? 'text-success text-end fixed-width' : 'text-red text-end fixed-width',
                "value": (item) =>  item.amount ? ToCustomDecimal(item.amount) +' kr' : "",
                'thClass': 'ps-5',
            },
            swedbankFee: {
                'label': i18n.t('modelLabel.swedbankFee'),
                'sortable': true,
                'sortField': 'swedbankFee',
                'insideClass' : (item)=> item.swedbankFee && item.swedbankFee >= 0 ? 'text-success text-end fixed-width' : 'text-red text-end fixed-width',
                "value": (item) =>  item.swedbankFee ? ToCustomDecimal(item.swedbankFee) +' kr' : "",
                'thClass': 'ps-5',
            },
            description: {
                'label': i18n.t('modelLabel.description'),
                'sortable': true,
                'sortField': 'description',
                'value': (item) => item.description === 'Användarlön' ? i18n.t('modelLabel.userSalary') : item.description === 'Inkomstskatt' ? i18n.t('modelLabel.incomeTax') : item.description === 'Arbetsgivaravgift' ? i18n.t('modelLabel.employmentTax') : item.description || "",
            },
            balance: {
                'label': 'Balance',
                'sortable': true,
                'sortField': 'balance',
                'value': (item) => item.balance || "",
            },
            createdAt: {
                'label': i18n.t('modelLabel.createdAt'),
                'sortable': true,
                'sortField': 'createdAt',
                'value': (item) =>  item.createdAt ? ChangeDateFormat('dateTime', item.createdAt) : '' || "",
            },
            createdBy: {
                'label': createdBy,
                'sortable': true,
                'sortField': 'createdBy',
                'value': (item) =>  item.createdBy || "",
            },
            createdByName: {
                'label': createdBy,
                'sortable': true,
                'sortField': 'createdByName',
                'value': (item) =>  item.createdByName || "",
            }
        };

        return attributes;
    }
}

export default ResponseAccountRowModel;